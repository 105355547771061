import CaseCard from 'components/case-card';
import Column from 'components/column';
import Section from 'components/section';
import { graphql } from 'gatsby';
import React from 'react';
import Spacing from 'sections/spacing';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import RenderBlocks, { Blocks } from 'utils/RenderModules';

import * as styles from './case-archive.module.scss';

type CaseArchiveProps = {
  data: GatsbyTypes.CaseArchivePageQueryQuery;
};

const CaseArchive: React.FC<CaseArchiveProps> = ({ data }) => {
  const {
    datoCmsCaseArchive: archive,
    allDatoCmsCase: { cases },
  } = data;

  return (
    <>
      <HelmetDatoCms seo={archive?.seoMetaTags} />
      <Spacing backgroundColor={'flamenco'} size={'small'} />
      <Section innerGapX sectionClassName={styles.caseArchiveHero}>
        <Column xl={6} lg={6} md={6} sm={6}>
          {archive?.topLeftColumn && (
            <h1
              dangerouslySetInnerHTML={{
                __html: archive.topLeftColumn,
              }}
            />
          )}
        </Column>
        <Column xl={6} lg={6} md={6} sm={6}>
          {archive?.topRightColumn && (
            <h5
              dangerouslySetInnerHTML={{
                __html: archive.topRightColumn,
              }}
            />
          )}
        </Column>
      </Section>
      <Spacing
        backgroundColor={'flamenco'}
        bevel={'bottomRight'}
        bevelColor={'milk'}
        size={'xsmall'}
      />
      <Section className={styles.caseArchiveList} innerGap>
        {cases.map((caseItem) => (
          <Column key={caseItem.id} as={'article'} xl={6} lg={6} md={6} sm={6}>
            <CaseCard {...caseItem} />
          </Column>
        ))}
      </Section>
      <Spacing backgroundColor={'milk'} size={'medium'} />
      {archive?.bottomLogos && <RenderBlocks data={archive.bottomLogos as Blocks[]} />}
      <Spacing backgroundColor={'milk'} size={'medium'} />
    </>
  );
};

export default CaseArchive;

export const query = graphql`
  query CaseArchivePageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        slugs {
          model
          slug
        }
      }
    }
    datoCmsCaseArchive {
      seoMetaTags {
        tags
      }
      topLeftColumn
      topRightColumn
      bottomLogos {
        ...IconTextList
        ...TwoTextColumn
      }
    }
    allDatoCmsCase(skip: $skip, limit: $limit) {
      cases: nodes {
        ...CaseCard
      }
    }
  }
`;
